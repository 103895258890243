<template>
  <div class="privacy-container">
    <Header></Header>

    <div class="privacy-content">
      <div class="top-image">
        <div class="title">Privacy Policy</div>
      </div>
      <div class="privacy-list">
        <div class="privacy-items">
          <div class="title">
            The exclusive rights protection rules of Giftcard8.
          </div>
          <div class="description">
            Giftcard8 regards the protection of the privacy of platform users,
            and protecting privacy is a basic policy of Giftcard8. The statement
            in this statement explains the data collection and use in these
            situations. This variable policy is applicable to all related
            services of Giftcard8 and converted into Giftcard8 services. For
            migration or expansion, the content of the proprietary statement is
            replaced with Giftcard8. Update at any time without notice. The
            updated ownership statement is published on the web page again to
            effectively replace the original ownership statement.
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">What kind of information do we collect</div>
          <div class="description">
            <div class="description">
              1.In general, users can visit Giftcard8 website anonymously to
              view related service documents. When we need personal information
              that can identify you or information that can contact you, we will
              ask for your consent.
            </div>
            <div class="description">
              2.However, when registering for Giftcard8 or applying for a
              service of Giftcard8, we may collect this information: mobile
              phone number, bank card information, identity information, email
              address, and ask for your confirmation.
            </div>
            <div class="description">
              3.If you choose to contact us directly for user feedback, we may
              collect some information about you such as your name, email
              address, phone number and other personal information provided by
              you during the feedback.
            </div>
            <div class="description">
              4.For payment processing, you will be required to provide your
              bank account details including the bank name, account name and
              account number.
            </div>
            <div class="description">
              5.When you trading a gift card with us, we will get your gift card
              information, such as the gift card number and PIN.
            </div>
            <div class="description">
              6.Other information that we may collect which is not specifically
              listed here, but which we will use in accordance with this Privacy
              Notice or as otherwise disclosed at the time of collection.
            </div>
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">How do we use your information</div>
          <div class="description">
            We will use the information we collect in a variety of ways,
            including to:
          </div>
          <div class="description">
            1.Provide, operate and maintain our website
          </div>
          <div class="description">
            2.Improve and optimize our services
          </div>
          <div class="description">
            3.Understand and analyze your preferences for using our website
          </div>
          <div class="description">
            4.Upgrade, develop and personalize our products
          </div>
          <div class="description">
            5.We may communicate with you directly as permitted by law,
            including for customer service, push site-related updates and other
            information, and for marketing and promotional purposes
          </div>
          <div class="description">
            6.Respond to your concerns and feedback
          </div>
          <div class="description">
            7.Find and prevent fraudulent activities
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">About your personal information</div>
          <div class="description">
            Giftcard8 strictly protects the security of your personal
            information. We strive to adopt various reasonable physical,
            electronic and management security measures to protect your
            information so that your information will not be leaked, destroyed
            or lost, including but not limited to SSL, information encrypted
            storage, and data center access control . We have also adopted
            strict management of employees or extended personnel who may have
            access to your information, including but not limited to adopting
            different authority controls according to different positions,
            signing confidentiality agreements with them, and monitoring.
            Giftcard8 will provide corresponding information based on existing
            technology. Security measures to protect your information and
            provide reasonable security. Giftcard8 will try its best to prevent
            your information from being leaked, damaged or lost.
          </div>
          <div class="description">
            The security protection function shared by your account, please keep
            your account and password information properly. Giftcard8 will
            ensure that your information is not lost, abused or altered by
            backing up to other servers and encrypting user passwords. There are
            complete security measures, but at the same time, please understand
            that there is no “perfect security measure” on the information
            network.
          </div>
          <div class="description">
            Giftcard8 will not share this information with third parties without
            your permission, except for the above-mentioned situations already
            listed in this statement.
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">Delete account data</div>
          <div class="description">
            After you register your account, in the Giftcard8 APP Settings > Account Security page, you can click
            "Delete Account". After confirming and submitting, the account cannot be logged in. The relevant data will
            be retained for 30 days, and the system will delete the relevant account data after 30 days.
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">Children's Information</div>
          <div class="description">
            Our priority is to protect some of the children use the Internet,
            but also encourage parents and guardians to participate in
            observation or monitoring guide their Internet activity.
          </div>
          <div class="description">
            Our service is not for anyone under the age of four, also won't
            deliberately to collect some information, if the children without
            the guardian consent provided us with information, you can contact
            us, We will delete such information as soon as is reasonably
            possible.
          </div>
        </div>
        <div class="privacy-items">
          <div class="title">Contact Details</div>
          <div class="description">
            Email：gcard8business@giftcard8.com.
          </div>
          <div class="description">
            Whatsapp: +8618179849917
          </div>
        </div>
        <div style="text-align: center;font-weight: bold;">This page was last update in June 2022</div>
      </div>
      <el-backtop :bottom="100">
        <div style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          ">
          UP
        </div>
      </el-backtop>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Privacy",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });

    return {};
  },
});
</script>

<style lang="less">
.privacy-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);

  .privacy-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/card_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;

      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }

    .privacy-list {
      position: relative;
      overflow: hidden;
      margin: 20px 200px;
      padding: 80px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;

      .privacy-items {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: left;

        .title {
          margin-bottom: 20px;
          color: rgba(51, 51, 51, 1);
          font-size: 26px;
        }

        .description {
          line-height: 25px;
          margin-bottom: 30px;

          span {
            color: #0f4392;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
