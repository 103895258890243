<template>
  <div class="home-container">
    <Header active="Home" @countryChange="countryChange"></Header>
    <div class="home-content">
      <!-- 顶部图片 -->
      <div class="top-image">
        <div class="top-download-box">
          <div class="apple_download hvr-grow" @click="appleDownload"></div>
          <div class="google_download hvr-grow" @click="googleDownload"></div>
        </div>
      </div>
      <!-- 各社区图标 -->
      <div class="community-list">
        <el-tooltip
          class="box-item"
          effect="light"
          :content="item.tooltip"
          placement="top"
          v-for="(item, index) in communityList"
          :key="index"
        >
          <a v-if="item.path" :href="item.path" target="_blank">
            <el-image :src="item.url"></el-image>
          </a>
          <el-image v-else :src="item.url"></el-image>
        </el-tooltip>
      </div>
      <!-- 菜单 -->
      <div class="menu">
        <div class="menu-list">
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/customer_service.png" />
            <h5>
              <countTo :startVal="1" :endVal="300" :duration="3000"></countTo> K
            </h5>
            <div>Sincere and quality service</div>
          </div>
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/order.png" />
            <h5>
              <countTo :startVal="1" :endVal="2" :duration="3000"></countTo>
              Millions
            </h5>
            <div>Leading order volume</div>
          </div>
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/security.png" />
            <h5>
              <countTo :startVal="1" :endVal="101" :duration="3000"></countTo>
              Score
            </h5>
            <div>Safer than 100 score</div>
          </div>
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/operations.png" />
            <h5>
              <countTo :startVal="1" :endVal="4" :duration="3000"></countTo>
              Year
            </h5>
            <div>Operating from 2018</div>
          </div>
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/trading.png" />
            <h5>
              <countTo :startVal="1" :endVal="50" :duration="3000"></countTo>+
            </h5>
            <div>up to 50+ gift card options</div>
          </div>
          <div
            class="menu-items hvr-grow-shadow animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <img src="../../assets/images/likes.png" />
            <h5>
              <countTo
                :startVal="1"
                :endVal="5"
                :duration="5000"
                :decimals="1"
              ></countTo>
            </h5>
            <div>Rave reviews among customers</div>
          </div>
        </div>
      </div>
      <!-- 汇率 -->
      <div class="rate">
        <div class="rate-content">
          <div
            class="rate-left-content animate"
            data-ani="fadeInLeft"
            data-aniremove="fadeOutLeft"
          >
            <div class="rate-title">Best exchange rate</div>
            <div class="rate-description">
              We keep the top rates of all kinds of we need,and
              <span
                ><countTo :startVal="1" :endVal="5" :duration="3000"></countTo
                >%</span
              >
              higher than other platforms.
            </div>
            <div class="rate-end">
              Follow our app get more information of transactions.
            </div>
            <div class="download-btn-box">
              <div
                class="apple_download hvr-pulse-grow"
                @click="appleDownload"
              ></div>
              <div
                class="google_download hvr-pulse-grow"
                @click="googleDownload"
              ></div>
            </div>
          </div>
          <div
            class="rate-right-content animate"
            data-ani="fadeInUp"
            data-aniremove="fadeOutDown"
          >
            <el-scrollbar class="rate-list">
              <li
                class="rate-items hvr-shrink"
                :class="index % 2 == 0 ? 'single' : 'double'"
                v-for="(item, index) in rateListData"
                :key="item"
              >
                <el-image
                  class="top-three-icon"
                  :src="require('../../assets/images/top_fire.png')"
                  v-if="index <= 2"
                ></el-image>
                <el-image
                  :src="item.category_image"
                  fit="contain"
                  style="max-width: 20px;max-height: 20px;"
                ></el-image>
                <div class="rate-name">{{ item.category_name }}</div>
                <div class="rate-specification">
                  {{ item.top_name }}({{ item.top_currency }})
                </div>
                <div class="rate-price">
                  {{ item.country_currency_symbol }}{{ item.top_rate }}/{{
                    item.top_currency_symbol
                  }}1
                </div>
              </li>
            </el-scrollbar>
          </div>
        </div>
      </div>
      <!-- 体验 -->
      <div class="experience">
        <div class="experience-content">
          <div
            class="experience-left animate"
            data-ani="fadeInLeft"
            data-aniremove="fadeOutLeft"
          >
            <img src="../../assets/images/experience_bg.png" />
          </div>
          <div
            class="experience-right animate"
            data-ani="fadeInRight"
            data-aniremove="fadeOutRight"
          >
            <div class="experience-title">Pleasant & Easy experience</div>
            <div class="experience-description">
              <span class="point">· </span>One-click trading is<span>
                so easy</span
              >; <br />
              <span class="point">· </span>Smooth<span> running speed</span>, no
              delay<br />
              <span class="point">· </span>Start trading like surfing!
            </div>
            <div class="download-btn-box">
              <div
                class="apple_download hvr-pulse-grow"
                @click="appleDownload"
              ></div>
              <div
                class="google_download hvr-pulse-grow"
                @click="googleDownload"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 难以置信的回扣金额 -->
      <div class="incredible">
        <div class="incredible-content">
          <div
            class="incredible-left animate"
            data-ani="fadeInLeft"
            data-aniremove="fadeOutLeft"
          >
            <div class="incredible-title">Incredible rewards</div>
            <div class="incredible-description">
              <span class="point">· </span>Get a <span>rebate</span> on your
              first order <br /><span class="point">· </span>Invite friends to
              place an order to get a <span>reward</span> <br /><span
                class="point"
                >· </span
              >Get a <span>reward</span> when the order reaches the amount
              <br /><span class="point">· </span><span>Reward </span>according
              to VIP level<br />
              <span class="point">· </span>Download our APP
              <span>get more rewards</span> information
            </div>
            <div class="download-btn-box">
              <div
                class="apple_download hvr-pulse-grow"
                @click="appleDownload"
              ></div>
              <div
                class="google_download hvr-pulse-grow"
                @click="googleDownload"
              ></div>
            </div>
          </div>
          <div
            class="incredible-right animate"
            data-ani="fadeInRight"
            data-aniremove="fadeOutRight"
          >
            <img src="../../assets/images/incredible_bg.png" />
          </div>
        </div>
      </div>
      <!-- 订单 -->
      <div class="order">
        <div class="order-content">
          <div
            class="order-left animate"
            data-ani="fadeInLeft"
            data-aniremove="fadeOutLeft"
          >
            <img src="../../assets/images/order_bg.png" />
          </div>
          <div
            class="order-right animate"
            data-ani="fadeInRight"
            data-aniremove="fadeOutRight"
          >
            <div class="order-title">The fastest order processing speed</div>
            <div class="order-description">
              <span>7 days X 24 hours is available online</span>
              <p>We will sincerely hear your feedback and comments.</p>
              <p>Looking forward to your contact.</p>
            </div>
            <div class="download-btn-box">
              <div
                class="apple_download hvr-pulse-grow"
                @click="appleDownload"
              ></div>
              <div
                class="google_download hvr-pulse-grow"
                @click="googleDownload"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部卡片 -->
      <div
        class="bottom-card animate"
        data-ani="fadeInUp"
        data-aniremove="fadeOutDown"
      >
        <div class="bottom-card-content">
          <div>
            security, instant, and best rate The perfect trading experience
            starts with Giftcard8.
          </div>
          <div class="download-tips-btn">
            <div class="download-tips">
              Download the app right now, <br />and get a good start !~
            </div>
            <div class="download-btn">
              <div class="apple_download hvr-grow" @click="appleDownload"></div>
              <div
                class="google_download hvr-grow"
                @click="googleDownload"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>
    <img
      src="../../assets/images/gmail_header_img.png"
      alt=""
      style="display:none;"
    />
    <Footer></Footer>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
import countTo from "vue-count-to/src";
import { getRateListApi } from "@/api/index";

export default defineComponent({
  name: "Home",
  components: { Header, Footer, countTo },
  setup() {
    let rateListData = reactive([]);
    const communityList = reactive([
      {
        url: require("../../assets/images/ins.png"),
        path: "https://www.instagram.com/giftcard8business/",
        tooltip: "Instagram",
      },
      {
        url: require("../../assets/images/facebook.png"),
        path: "https://www.facebook.com/Giftcard8-business-115623504478430",
        tooltip: "Facebook",
      },
      {
        url: require("../../assets/images/email2.png"),
        path: "",
        tooltip: "Email:Gcard8business@giftcard8.com",
      },
      {
        url: require("../../assets/images/whatapp.png"),
        path: "",
        tooltip: "WhatsApp:+8618179849917",
      },
      {
        url: require("../../assets/images/twitter.png"),
        path: "https://twitter.com/Giftcard_8",
        tooltip: "Twitter",
      },
    ]);
    const appleDownload = () => {
      window.open(
        "https://apps.apple.com/cn/app/giftcard8-sell-gift-cards/id1603211026"
      );
    };
    const googleDownload = () => {
      window.open(
        "https://play.google.com/store/apps/details?id=com.yihua.giftcard"
      );
    };
    const refreshData = () => {
      window.location.reload();
    };
    const countryChange = (countryId) => {
      getRateList(countryId);
    };
    const getRateList = (countryId) => {
      let data = {
        country_id: countryId || 14,
      };
      rateListData.length = 0;
      getRateListApi(data).then((res) => {
        console.log(res);
        if (res.data) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              if (item.category_image) {
                rateListData.push(item);
              }
            });
          } else {
            console.log("rate is No Data");
          }
        }
      });
    };
    onMounted(() => {
      document.documentElement.scrollTop = 0;
      if (!sessionStorage.getItem("token")) {
        console.log("用户已退出");
        window.Intercom("boot", {
          app_id: "p9yoo8fv",
          horizontal_padding: 30,
          vertical_padding: 20,
          action_color: "rgba(0,113,178, 0.5)",
        });
      } else {
        window.Intercom("boot", {
          app_id: "p9yoo8fv",
          horizontal_padding: 30,
          vertical_padding: 20,
          action_color: "rgba(0,113,178, 0.5)",
          user_id: sessionStorage.getItem("account"),
          email: sessionStorage.getItem("email"),
          created_at: "1651729606",
        });
      }
      getRateList(sessionStorage.getItem("country_id"));
    });
    return {
      appleDownload,
      googleDownload,
      rateListData,
      refreshData,
      communityList,
      countryChange,
    };
  },
  methods: {
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animate");
      [].slice.call(dom).forEach((v) => {
        if (top + vh - 100 > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
              v.classList.remove(v.dataset.aniremove);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
            v.classList.remove(v.dataset.aniremove);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.classList.add(v.dataset.aniremove);
          v.style.opacity = 0;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleAnimate);
    });
  },
  unmounted() {
    removeEventListener("scroll", this.handleAnimate);
  },
});
</script>

<style lang="less" scoped>
.animate {
  opacity: 0;
}
.fadeInUp {
  animation: fadeInUp 1s;
}
.fadeOutDown {
  animation: fadeOutDown 1s;
}
.fadeInLeft {
  animation: fadeInLeft 1s;
}
.fadeOutLeft {
  animation: fadeOutLeft 1s;
}
.fadeInRight {
  animation: fadeInRight 1s;
}
.fadeOutRight {
  animation: fadeOutRight 1s;
}
.rollIn {
  animation: rollIn 1s;
}
.slideInRight {
  animation: slideInRight 1s;
}
.bounceInLeft {
  animation: bounceInLeft 2s ease-in;
}
.bounceInRight {
  animation: bounceInRight 2s ease-in;
}
.home-container {
  position: relative;
  padding-top: 100px;
  overflow-y: auto;
  .home-content {
    .point {
      font-weight: bold;
    }
    .top-image {
      position: relative;
      height: 50rem;
      background-image: url("../../assets/images/home_top.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      .top-download-box {
        position: absolute;
        left: 17%;
        top: 70%;
        display: flex;
      }
    }
    .apple_download {
      width: 250px;
      height: 72px;
      background-image: url("../../assets/images/apple_download_icon.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      z-index: 1;
      cursor: pointer;
    }
    .apple_download:hover {
      background-image: url("../../assets/images/apple_download_hover_icon.png");
    }
    .google_download {
      width: 250px;
      height: 72px;
      margin-left: 20px;
      background-image: url("../../assets/images/google_download_icon.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      z-index: 1;
      cursor: pointer;
    }
    .google_download:hover {
      background-image: url("../../assets/images/google_download_hover_icon.png");
    }
    .download-btn-box {
      margin-top: 60px;
      display: flex;
      .apple_download,
      .google_download {
        width: 32px;
        height: 32px;
        margin-right: 50px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;

        cursor: pointer;
      }
      .apple_download {
        background-image: url("../../assets/images/apple_header_hover.png");
      }
      .apple_download:hover {
        background-image: url("../../assets/images/apple_header_hover.png");
      }
      .google_download {
        background-image: url("../../assets/images/Google_header_hover.png");
      }
      .google_download:hover {
        background-image: url("../../assets/images/Google_header_hover.png");
      }
    }
    .community-list {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8faff;
      .el-image {
        margin: 80px 40px;
        cursor: pointer;
      }
    }
    .menu {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.02);
      display: flex;
      justify-content: center;
      .menu-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 80px 250px;
        .menu-items {
          width: 390px;
          height: 290px;
          margin: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0px 4px 20px 1px rgba(43, 89, 255, 0.0784313725490196);
          cursor: pointer;
          h5 {
            margin: 30px 0 16px 0;
            font-size: 40px;
          }
          img {
            width: 100px;
            height: 100px;
          }
        }
        .menu-items:hover {
          background-color: rgba(28, 98, 206, 0.1);
        }
      }
    }
    .rate {
      width: 100%;
      padding: 10px 0;
      background-color: #f8faff;
      .rate-content {
        height: 100%;
        margin: 180px 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        .rate-left-content {
          text-align: left;
          font-size: 34px;
          .rate-title {
            color: #0f4392;
            font-size: 40px;
            font-weight: bold;
          }
          .rate-description {
            margin-top: 40px;
            color: #666666;
            span {
              color: #0f4392;
              font-weight: bold;
            }
          }
          .rate-end {
            margin-top: 20px;
            color: #666666;
            font-size: 34px;
            font-weight: bold;
          }
        }
        .rate-right-content {
          margin-left: 40px;
          .rate-list {
            position: relative;
            height: 560px;
            overflow-y: auto;
            border-radius: 5px;
            .rate-items {
              width: 650px;
              height: 56px;
              padding-left: 10px;
              display: flex;
              align-items: center;
              img {
                width: 26px;
                height: 26px;
                margin-left: 40px;
              }
              .top-three-icon {
                width: 12px;
                height: 14px;
                margin-right: 10px;
              }
              .rate-name {
                margin-left: 24px;
                width: 140px;
                text-align: left;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
              }
              .rate-specification {
                flex: 1;
                padding-left: 20px;
                text-align: left;
              }
              .rate-price {
                margin-right: 40px;
              }
            }
            .rate-items.single {
              background-color: rgba(55, 124, 253, 0.05);
            }
            .rate-items.double {
              background-color: rgba(252, 252, 252, 1);
            }
          }
        }
      }
    }
    .experience {
      width: 100%;
      height: 800px;
      font-size: 34px;
      background-color: rgb(255, 255, 255);
      .experience-content {
        height: 100%;
        margin: 180px 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        .experience-left {
          img {
            width: 80%;
            height: 80%;
          }
        }
        .experience-right {
          margin-left: 40px;
          text-align: left;
          .experience-title {
            color: #0f4392;
            font-size: 40px;
            font-weight: bold;
          }
          .experience-description {
            margin-top: 40px;
            color: #666666;
            text-align: left;
            span {
              color: #0f4392;
              font-weight: bold;
            }
          }
          .download-btn-box {
            margin-top: 60px;
            display: flex;
            img {
              width: 32px;
              height: 32px;
              margin-right: 50px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .incredible {
      width: 100%;
      background-color: #f8faff;
      font-size: 34px;
      .incredible-content {
        height: 100%;
        margin: 80px 250px 0 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        .incredible-left {
          text-align: left;
          .incredible-title {
            color: #0f4392;
            font-size: 40px;
            font-weight: bold;
          }
          .incredible-description {
            margin-top: 40px;
            color: #666666;
            text-align: left;
            span {
              color: #0f4392;
              font-weight: bold;
            }
          }
          .download-btn-box {
            margin-top: 60px;
            display: flex;
            img {
              width: 32px;
              height: 32px;
              margin-right: 50px;
              cursor: pointer;
            }
          }
        }
        .incredible-right {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 40px;
          img {
            width: 80%;
            height: 80%;
          }
        }
      }
    }
    .order {
      width: 100%;
      height: 800px;
      background-color: rgb(255, 255, 255);
      font-size: 34px;
      .order-content {
        height: 100%;
        margin: 0 250px 80px 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        .order-left {
          img {
            width: 80%;
            height: 80%;
          }
        }
        .order-right {
          text-align: left;
          .order-title {
            color: #0f4392;
            font-size: 39px;
            font-weight: bold;
          }
          .order-description {
            margin-top: 40px;
            color: #666666;
            text-align: left;
            span {
              color: #0f4392;
              font-weight: bold;
            }
          }
          .download-btn-box {
            margin-top: 60px;
            display: flex;
            img {
              width: 32px;
              height: 32px;
              margin-right: 50px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .bottom-card {
      padding: 10px 0;
      font-size: 28px;
      color: #fff;
      background-color: #f8faff;

      border-radius: 20px;
      text-align: left;
      .bottom-card-content {
        margin: 80px 250px;
        padding: 80px 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        background-image: url("../../assets/images/card_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      .download-tips-btn {
        display: flex;
        margin-top: 60px;
        .download-tips {
          flex: 1;
          font-size: 40px;
        }
        .download-btn {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: self-end;

          img {
            width: 250px;
            height: 72px;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
